<template>
  <div class="page-folder">
    <div class="my-page">
      <div class="list-box">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getDataList"
            offset="10"
          >
            <div class="my-card" v-for="(item, i) in dataList" :key="i" @click="handleClick(item)">
              <div class="card-main">
                <div class="left"></div>
                <div class="right">
                  <div class="card-top">
                    <div class="card-title">{{ item.FolderName }}</div>
                    <div class="button" v-if="userType == '2' && item.IsCreateUser">
                      <span class="edit-btn" @click.stop="editOpen(item)">
                        <van-icon name="edit" />
                        <span class="edit">编辑</span>
                      </span>
                      <span class="xj-btn" @click.stop="delOpen(item)">
                        <van-icon name="delete-o" />
                        <span class="xj">删除</span>
                      </span>
                    </div>
                  </div>

                  <div class="card-mid">
                    <div class="name">{{ item.RealName }}</div>
                    <span class="date">{{ item.CreateDate }}</span>
                  </div>
                </div>
              </div>

              <div class="card-bottom" >
                <div class="comment-d">
                  <div class="left">
                    <i class="i-eye"></i>
                    <span class="nosee">{{ item.UpCount }}人已上传</span>
                  </div>
                  <div class="right" v-if="item.IsUp">
                    <i class="i-upload"></i>
                    <span class="remind">我已上传</span>
                  </div>

                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <!-- 图片预览 -->
    <van-image-preview v-model="imgShow" :images="images"> </van-image-preview>
    <!-- 新增悬浮按钮 -->
    <div v-if="userType === '2'" class="add-btn" @click="add">+</div>
    <!-- 驳回弹窗 -->
    <van-dialog
      v-model="dialogVisible"
      title="提示"
      :beforeClose="beforeClose"
      showCancelButton
    >
      <div style="text-align: center; margin-top: 20px; margin-bottom: 20px">
        确定要删除该文件夹吗？
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {
  Button,
  List,
  Icon,
  PullRefresh,
  Image,
  ImagePreview,
  Dialog,
} from "vant";
export default {
  components: {
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      userType: window.localStorage.getItem("UserType"),
      loading: false,
      finished: false, // false
      refreshing: false,
      dataList: [],
      page: 0, // 页码
      limit: 10, // 每页条数
      imgShow: false,
      images: [],
      dialogVisible: false,
      id: null,
    };
  },
  mounted() {
    // 空白时候自动触发
    // this.getDataList()
  },
  methods: {
    getDataList() {
      this.page++;
      this.getList();
    },
    getList() {
      const that = this;
      that.$axios
        .get("/api/ClassFolder/List", {
          page: this.page, // 页码
          limit: this.limit, // 每页条数
        })
        .then((res) => {
          if (res.code === 200) {
            that.refreshing = false;
            that.loading = false;
            that.dataList = that.dataList.concat(res.data);
            if (that.dataList.length >= res.count) {
              that.finished = true;
            }
          } else {
            that.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    // 下拉刷新
    onRefresh() {
      this.page = 0;
      // 清空列表数据
      this.finished = false;
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true
      this.dataList = [];
      this.getDataList();
    },
    handleClick(item) {
      this.$router.push({
        path: "FolderView",
        query: {
          id: item.ID,
        },
      });
    },
    openOrClose(e) {
      const dom = this.$jq(e.currentTarget).find("i");
      if (dom.hasClass("i-down")) {
        dom.removeClass("i-down");
        dom.parent().parent().parent().parent().siblings(".card-bottom").show();
      } else {
        dom.addClass("i-down");
        dom.parent().parent().parent().parent().siblings(".card-bottom").hide();
      }
    },
    add() {
      this.$router.push("FolderEdit");
    },
    editOpen(item){
      this.$router.push({path:"FolderEdit",query:{
        id: item.ID,
      }});
    },
    delOpen(item) {
      this.id = item.ID;
      this.dialogVisible = true;
    },
    beforeClose(action, done) {
      let that = this;
      if (action === "confirm") {
        this.$axios
          .post("/api/ClassFolder/Delete", {
           
            ID: this.id,
          })
          .then((res) => {
            if (res.code === 200) {
              this.$toast.success(res.msg || "操作成功");
             
              done();
              setTimeout(() => {
                // that.$router.go(0)
                window.location.reload();
              }, 1000);
            } else {
              this.$toast.fail(res.msg || "操作失败");
            }
          });
      } else {
        done();
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
