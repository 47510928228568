<template>
  <div class="page-auditschedule">
    <div class="tab-box">
      <div
        class="tab-item"
        v-for="(num, index) in tabs"
        :key="index"
        :class="{ active: index === tabNum }"
        @click="tabClick(index)"
      >
        {{ num }}
      </div>
    </div>
    <all v-if="tabNum === 0"  @openDelet='openDelet' ref="all"/>
    <class v-if="tabNum === 1" @openDelet='openDelet' ref="class" />
    <children v-if="tabNum === 2"  @openDelet='openDelet' ref="children"/>
   
    <notice v-if="tabNum === 3" />
     <folder v-if="tabNum === 4" />

    <!-- 菜单栏 -->
		<NavBar v-bind:active="1" />
  </div>
</template>
  
  <script>
import { Icon, Image, ImagePreview,Dialog } from "vant";
import NavBar from "@/components/NavBar";
import All from "./all.vue";
import Class from "./class.vue";
import Children from "./children.vue";
import Folder from '../Folder/List/index.vue'
import Notice from '../Notice/NoticeList/index.vue'

export default {
  components: {
    All,
    Class,
    Children,
    Folder,
    Notice,
    NavBar,
	[Dialog.name]: Dialog,
  },
  data() {
    return {
      tabs: ["全部", "班级圈", "宝贝圈", "通知公告","文件上传"],
      tabNum: 0,
	  Principal: window.localStorage.getItem("Principal"),
    };
  },
  mounted() {
    // this.getUser()
    if (this.$route.query.active) {
      this.tabNum = Number(this.$route.query.active);
    }
  },
  methods: {
    tabClick(index) {
      this.tabNum = index;
    },
	// 打开删除朋友圈弹窗
	openDelet(item) {
		Dialog.alert({
			message: '确认删除该条朋友圈?',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonColor: '#ffe500'
		}).then(() => {
			// 删除接口
			this.$axios.post(item.CircleType == 1 ? '/api/ClassCircle/Delete' : '/ChildrenCircle/Delete', {
				ID: item.ID
			}).then(res => {
				if (res.code == 200) {
					this.$toast.success(res.msg)
					if(this.tabNum == 0){
						this.$nextTick(()=>{
							this.$refs.all.onRefresh()
						})				
					}else if(this.tabNum == 1){
						this.$nextTick(()=>{
							this.$refs.class.onRefresh()
						})		
					}else {
						this.$nextTick(()=>{
							this.$refs.children.onRefresh()
						})
					}
				} else {
					that.$toast.fail(res.msg || "操作失败");
				}
			})
		})
	}
  },
};
</script>
  <style lang="less" scoped>
@import "./index.less";
</style>
  