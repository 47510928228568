<template>
  <!-- 全部 -->
  <div class="circleShare">
    <!-- 分享日常 -->
    <shareDaily
      ref="shareDaily"
      v-show="$parent.Principal == 'false'"
    ></shareDaily>
    <div class="shareDaily" v-if="Principal == 'true'">
      <div class="my-card">
        <div class="left" @click="searchClass">
          <img
            src="../../assets/home/班级搜索.png"
            style="width: 30px;height: 30px;position: relative;bottom: 3px;"
          />
          <van-field
            v-model="ClassName"
            placeholder="请选择班级"
            style="display:width:60%;padding-left: 10px;"
            disabled
          />
        </div>
        <div class="right" @click="reset">
          <img
            src="../../assets/home/班级重置.png"
            style="width: 35px;height: 35px;"
          />
        </div>
      </div>
    </div>
    <div class="shareContent" style="height:76vh; overflow: auto">
      <van-pull-refresh
        v-model="refreshing"
        success-text="刷新成功"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getMore"
        >
          <div
            class="my-card"
            v-for="item in tabList[this.tabNumer]"
            :key="item.ID"
          >
            <!-- 顶部用户信息 -->
            <div class="topUseInfo">
              <div class="left">
                <van-image
                  width="40"
                  height="40"
                  radius="10"
                  :src="item.ProfilePicture"
                />
                <div class="text">
                  <p style="font-size:15px;">
                    {{ item.CreateUser
                    }}<span v-if="item.ClassName">{{
                      "（" + item.ClassName + "）"
                    }}</span>
                  </p>
                  <p>{{ item.CreateDate }}</p>
                </div>
              </div>
              <div
                class="right"
                @click="openDelet(item)"
                v-show="item.IsUseDelete"
              >
                <van-icon name="ellipsis" size="20" />
              </div>
            </div>
            <!-- 中间内容 -->
            <div class="middleContent">
              <p style="font-size:14px;">{{ item.Content }}</p>
              <div class="imgList">
                <div class="img">
                  <div style="display:flex;flex-wrap:wrap;width:100%">
                    <div
                      v-for="(itm, index) in item.AccessPostList"
                      :key="index"
                      :class="
                        itm.url.substring(itm.url.lastIndexOf('.')) == '.mp4' ||
                        itm.url.substring(itm.url.lastIndexOf('.')) == '.mov'
                          ? 'video_item'
                          : 'img_item'
                      "
                    >
                      <div
                        v-if="
                          itm.url.substring(itm.url.lastIndexOf('.')) ==
                            '.mp4' ||
                            itm.url.substring(itm.url.lastIndexOf('.')) ==
                              '.mov'
                        "
                        style="width: 100%;height: 200px;margin-top:10px"
                      >
                        <video
                          controls
                          playsinline
                          webkit-playsinline
                          x-webkit-airplay="allow"
                          preload="metadata"
                          style="width: 100%;height: 200px;object-fit: cover;"
                          loop
                          class="video"
                          :src="itm.url"
                          :poster="
                            itm.url + '?x-oss-process=video/snapshot,t_1,m_fast'
                          "
                        ></video>
                      </div>
                      <div v-else>
                        <div
                          style="width: 100%;height: 100px;margin-top: 10px;"
                        >
                          <van-image
                            radius="10"
                            style="width: 100%;height: 100%;"
                            :src="itm.url"
                            @click.stop="
                              () => {
                                images = [];
                                images = item.imgList;
                                imgShow = true;
                              }
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 底部标签、点赞 -->
            <div class="">
              <div class="">
                <span class="daulyTitle">{{ "#" + item.SignTypeName }}</span>
                <div class="thumbsUp">
                  <div class="left">
                    <div v-if="item.thumbUpUserList.length != 0">
                      <img
                        style="border-radius:10px;"
                        v-for="(thumbupItem, index) in item.thumbUpUserList"
                        :key="index"
                        :src="thumbupItem"
                        width="20"
                        height="20"
                      />
                    </div>
                    <span
                      style="color: #a6a6a6"
                      :style="
                        item.thumbUpUserList.length != 0
                          ? 'margin-left:10px'
                          : ''
                      "
                      >共{{ item.ThumbUpCount }}人点赞</span
                    >
                  </div>
                  <div
                    class="right"
                    @click="thumbsUpClick(item, item.CircleType, item.ID)"
                  >
                    <img
                      src="../../assets/home/undz.png"
                      v-if="!item.checked"
                      class="dz"
                    />
                    <img src="../../assets/home/dz.png" v-else class="dz" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- 图片预览 -->
    <van-image-preview v-model="imgShow" :images="images"> </van-image-preview>
    <!-- 暂无数据 -->
    <!-- <van-empty description="暂无数据" v-else /> -->
    <!-- 班级 -->
    <pickSelect
      ref="classPick"
      :columns="classList"
      @onConfirm="onConfirmClass"
      valueKey="ClassName"
    ></pickSelect>
  </div>
</template>

<script>
import shareDaily from "../../pages/TabBar/Home/component/shareDaily.vue";

import {
  Col,
  Row,
  PullRefresh,
  Image as VanImage,
  Empty,
  List,
  ImagePreview,
  Icon,
  Field,
  Picker
} from "vant";
import pickSelect from "@/components/common/pickSelect/index.vue";
export default {
  name: "CircleShare",
  components: {
    shareDaily,
    [VanImage.name]: VanImage,
    [Col.name]: Col,
    [Row.name]: Row,
    [PullRefresh.name]: PullRefresh,
    [Empty.name]: Empty,
    [List.name]: List,
    [Icon.name]: Icon,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Field.name]: Field,
    [Picker.name]: Picker,
    pickSelect
  },
  data() {
    return {
      userType: window.localStorage.getItem("UserType"),
      loading: false,
      tabList: [[], [], [], []], //朋友圈列表数据
      tabNumer: 0, //按钮index
      type: [
        {
          CircleType: 0,
          limit: 5,
          page: 0
        },
        {
          CircleType: 1,
          limit: 5,
          page: 0
        },
        {
          CircleType: 2,
          limit: 5,
          page: 0
        },
        {
          CircleType: 3,
          limit: 5,
          page: 0
        }
      ], //圈子类型
      finished: false, //是否更多数据
      refreshing: false, //下拉刷新数据
      imgShow: false,
      images: [],
      classList: [],
      ClassID: null,
      ClassName: "",
      Principal: window.localStorage.getItem("Principal")
    };
  },
  mounted() {
    this._getClassList();
    //this._getCircleInfo()
  },
  methods: {
    // 重置
    reset() {
      this.ClassID = null;
      this.ClassName = "";
      this.type[this.tabNumer].classId = null;
      this.type[this.tabNumer].page = 1;
      this.tabList = [[], [], [], []];
      this._getCircleInfo();
    },
    // 获取所有班级
    _getClassList() {
      this.$axios.get("/Class/SelectClassList").then(res => {
        if (res.code == 200) {
          this.classList = res.data;
        }
      });
    },
    // 班级确认
    onConfirmClass(value) {
      this.ClassID = value.ID;
      this.ClassName = value.ClassName;
      this.$refs.classPick.showPick = false;
      this.type[this.tabNumer].classId = value.ID;
      this.type[this.tabNumer].page = 1;
      this.tabList = [[], [], [], []];
      this._getCircleInfo();
    },
    //搜索班级
    searchClass() {
      this.$refs.classPick.showPick = true;
    },
    // 朋友圈信息
    _getCircleInfo() {
      this.$axios
        .get("/Main/GetCircleList", this.type[this.tabNumer])
        .then(res => {
          if (this.refreshing) {
            this.refreshing = false;
          }
          if (res.code == 200) {
            if (res.data && res.data.length > 0) {
              res.data.forEach(item => {
                console.log(item.IsThumbUp);
                item.checked = item.IsThumbUp;
              });
              this.tabList[this.tabNumer].push(...res.data);
              this.tabList[this.tabNumer].forEach(item => {
                if (item.AccessPostList && item.AccessPostList.length > 0) {
                  let videoList = [];
                  let imgList = [];
                  item.AccessPostList.forEach(accessItem => {
                    if (
                      accessItem.url.substring(
                        accessItem.url.lastIndexOf(".")
                      ) == ".mp4" ||
                      accessItem.url.substring(
                        accessItem.url.lastIndexOf(".")
                      ) == ".mov"
                    ) {
                      videoList.push(accessItem);
                    } else {
                      imgList.push(accessItem.url);
                    }
                  });
                  item.videoList = videoList;
                  item.imgList = imgList;
                }
              });
              // 数据深层数据无法监听,强刷新实现响应式
              this.$forceUpdate();
              this.loading = false;
            } else {
              this.finished = true;
            }
          }
        });
    },
    // 上拉加载更多数据
    getMore() {
      this.type[this.tabNumer].page++;
      //this.loading = true
      this._getCircleInfo();
    },
    // 下拉刷新刷新数据状态
    onRefresh() {
      this.finished = false;
      this.type[this.tabNumer].page = 1;
      this.tabList[this.tabNumer] = [];
      //this.loading = true
      this._getCircleInfo();
    },
    // 点赞
    thumbsUpClick(item, CircleType, ID) {
      // 班级圈点赞接口
      this.$axios
        .post(
          CircleType == 1
            ? "/api/ClassCircle/ThumbUp"
            : "/api/ChildrenCircle/ThumbUp",
          CircleType == 1
            ? {
                ClassCircleID: ID
              }
            : {
                ID
              }
        )
        .then(res => {
          // 刷新数据
          item.checked = !item.checked;
          if (item.checked) {
            item.thumbUpUserList.unshift(
              JSON.parse(window.localStorage.getItem("userInfo")).HeadUrl
            );
            item.ThumbUpCount++;
          } else {
            item.thumbUpUserList = item.thumbUpUserList.filter(
              item =>
                item !=
                JSON.parse(window.localStorage.getItem("userInfo")).HeadUrl
            );
            item.ThumbUpCount--;
          }
          this.$forceUpdate();
        });
    },
    openDelet(item) {
      this.$emit("openDelet", item);
    }
  }
};
</script>

<style lang="less" scoped>
.circleShare {
  padding: 0 15px;
}
.shareDaily {
  .my-card {
    display: flex;
    justify-content: space-between;
    border-radius: 40px;
    padding: 5px;
    padding-left: 8px;

    .left {
      display: flex;
      align-items: center;
    }

    .right {
      display: flex;
      align-items: center;
    }
  }
}

.my-card {
  padding-top: 20px;
  .img_item {
    width: 32%;
  }
  .img_item:nth-child(3n + 2) {
    margin: 0 2%;
  }

  .video_item {
    width: 100%;
  }
  .topUseInfo {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      justify-content: flex-start;
    }

    .text {
      margin-left: 10px;

      // p {
      //   text-align: center;
      // }
    }

    p:first-child {
      color: #000000;
      font-size: 14px;
    }

    p:last-child {
      color: #a6a6a6;
      font-size: 10px;
      margin-top: 8px;
    }
  }

  .middleContent {
    margin: 10px 0;

    .imgList {
      margin-top: 0px;
    }
  }

  .thumbsUp {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    //padding-right: 16px;

    .left {
      display: flex;
      align-items: center;
    }
  }
}
</style>
